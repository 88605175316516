<template>
  <div>
    <div class="buttons-container filters">
      <Button title="Pas Eligible" type="primary" :active="eligibleUser" @click="eligibleUser=true"/>
      <Button title="En attente" type="primary" :active="!eligibleUser" @click="eligibleUser=false"/>
    </div>
    
    <MonitoringDisplayBar :columns="columns" />

    <div class="monitoring-table">
      <table aria-describedby="monitoring-table">
        <thead>
          <tr>
            <th v-for="(group, i) in displayColumns" :key="`cols-${i}`" :colspan="group.fields.length" class="group group-head">
              {{ group.title }}
            </th>
          </tr>
          <tr>
            <template v-for="(group, i) in displayColumns">
              <template v-for="(col, j) in group.fields">
                <th :key="`col-${i}-${j}`"
                  :class="{sortable: col.sortable, group: j === 0}"
                  @click="sort(group, col)"
                >
                  {{ col.title }}
                </th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(patient, i) in sortedPatients" :key="`patient-${i}`">
            <template v-for="(column,cName) in columns" >
              <template v-if="column.display">
                <template v-for="field in column.fields">
                  <td v-if="field.type === 'button'" :key="`patientCell-${cName}-${field.name}`"><Button class="secondary" :title="field.name" @click="field.action(patient)"/></td>
                  <MonitoringPatientCell
                    v-else
                    :key="`patientCell-${cName}-${field.name}`"
                    :cellType="field.type"
                    :data="patient[field.name]||''"
                    :cellTitle="field.title"
                    :editable="field.editable"
                    @edit="value=>fieldEdited(patient,field.name,value)"
                  />
                </template>
              </template>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import MonitoringPatientCell from './MonitoringPatientCell'
import MonitoringDisplayBar from '@/components/Monitoring/MonitoringDisplayBar'

export default {
  name: 'MonitoringWaitingPatients',
  components: {
   Button,
   MonitoringPatientCell,
   MonitoringDisplayBar
  },
  data () {
    return {
      patientsList: [],
      columns: {
        main: {
          name: 'main',
          title: '',
          display: true,
          hidden: true,
          fields: [
            { title: 'Date', sortable: true, name: 'createdAt', type: 'date'},
            { title: 'Prénom', sortable: true, name: 'firstName', editable: true},
            { title: 'Nom', sortable: true, name: 'lastName', editable: true},
            { title: 'Email', name: 'email', editable: true},
            { title: 'Téléphone', name: 'phone', editable: true},
          ]
        },
        personalInformation: {
          name: 'personalInformation',
          title: 'Informations',
          display: false,
          fields: [
            { title: 'Poids', name: 'weight', editable: true},
            { title: 'Taille', name: 'height', editable: true},
            { title: 'Entre jambe', name: 'leg', editable: true},
            { title: 'Mobilité droite', name: 'right', editable: true, type: 'boolean'},
            { title: 'Mobilité gauche', name: 'left', editable: true, type: 'boolean'},
            { title: 'Adresse', name: 'address', editable: true, type: 'address'},
          ]
        },
        inscription: {
          name: 'Inscription',
          title: 'Inscription',
          display: false,
          fields: [
            { title: 'Contract', name: 'contractUrl', editable: true, type: 'file'},
            { title: 'Numéro de contrat', name: 'contractNumber', editable: true},
            { title: 'Prix d\'arrivé', name: 'upfrontCost', editable: true, type: 'number'},
            { title: 'Prix mensuel', name: 'monthlyCost', editable: true, type: 'number'},
            { title: 'Temps d\'engagement (mois)', name: 'engagementInMonths', editable: true, type: 'number'},
            { title: 'Session incluse', name: 'includedSessionsAtLaunch', editable: true, type: 'number'},
            { title: '', name:'Valider', type: 'button', action: this.validatePatient}
          ]
        },
        other: {
          name: 'other',
          title: 'Autre',
          display: false,
          fields: [
            { title: 'Etablissement', name: 'establishmentName', editable: true}
          ]
        }
      },
      sortBy: {
        group: 'main',
        key: 'date',
        order: 1
      },
      eligibleUser: true
    }
  },
  async created () {
    this.patientsList = await this.$store.dispatch('applications/getPatientsApplication', [21, 22])
  },
  methods: {
    async fieldEdited(patient, key, value) {
      const fields = {}
      if(key === 'contractUrl'){
        key = 'contractId'
      }
      fields[key] = value
      await this.$store.dispatch('applications/updatePatientApplication', {applicationId: patient.id, fields })
      patient[key] = value
    },
    sort (group, column) {
      if (column.sortable) {
        const { name } = column

        if (this.sortBy.key === name) {
          this.sortBy.order = -this.sortBy.order
        } else {
          this.sortBy = {
            group: group.name,
            key: name,
            order: -1
          }
        }
      }
    },
   async validatePatient(patient){
     try{
        await this.$store.dispatch('applications/validatePatientApplication', {applicationId: patient.id})
        this.$root.$emit("sendToast", {
          message: "Une email vient d'etre envoyé à l'utilisateur",
          type: "success",
        });
     } catch(e) {
        if(e.response?.status == 422){
          this.$root.$emit("sendToast", {
            message: "Cet utilisateur n'est pas eligible",
            type: "warning",
          });
        } else {
          this.$root.$emit("sendToast", {
            message: "Une erreur est survenue lors de l'opération",
            type: "error",
          });
        }
     }
    }
  },
  computed: {
    displayColumns () {
      const cols = []
      Object.keys(this.columns).forEach(group => {
        if (this.columns[group].display) {
          cols.push(this.columns[group])
        }
      })
      return cols
    },
    sortedPatients () {
      const order = this.sortBy.order
      const sortBy = this.sortBy.key
      return [...this.patientsList].sort((a, b) => {
        return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
      })
    }
  },
  watch: {
    async eligibleUser(eligible){
      this.patientsList = []
      if(eligible) {
        this.patientsList = await this.$store.dispatch('applications/getPatientsApplication', [21, 22])
      } else {
        this.patientsList = await this.$store.dispatch('applications/getPatientsApplication', [23])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  margin-top: 24px;
  display: flex;

  a{
    margin: 0 6px;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.monitoring-table{
  display: inline-block;
  overflow: scroll;
  width: 100%;
  padding-bottom: 20px;
}

table{
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;

  thead{
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .group{
      border-left: 2px solid $white-color;

      &:first-child{
        border-left: none;
      }
    }

    .group-head{
      font-size: 1.2rem;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }

    .sortable{
      cursor: pointer;
      transition: all 0.25s;

      &:hover{
        opacity: 0.75;
      }
    }
  }

  tbody{
    tr{
      &:nth-child(2n){
        background: #f7f7f7;
      }
    }
  }

  th, td{
    vertical-align: middle;
  }

  th{
    padding: 10px;
    user-select: none;
    vertical-align: bottom;
  }

  td{
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 4px;

    &:first-child{
      border-left: none;
    }
    &:last-child{
      border-right: none;
    }
  }

  .separator{
    border-left: 2px solid $main-color;
  }
}
</style>
